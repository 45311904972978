import React, { useEffect } from "react";
import { AppWrapper } from "../components/AppWrapper";
import { BackgroundSection } from "../components/Background/BackgroundSection";
import Footer from "../components/ui/Footer";
import { Header } from "../components/Header";
import Button from "../components/ui/Button";
import Title from "../components/ui/Title";
import firebase from "gatsby-plugin-firebase"
import { ANALYTIC_EVENT_NAMES } from "../constants/analytics"

const AUTH_CLIENT_ID = "nFf7ywPnRRqatomnFWzs7Dgzk2zWPvATgwbvM3A7eJhBfw";
const AUTH_CLIENT_SECRET = "aF7AyL9KtAQJ6c8uaepS8MMgAEnsRCj5JXdqNeIkTZ4i1n1H1eBIHSSh2HetuZ";
const baseUrl = "https://api.behappiz.com";

export default function IndexPage({ location }) {
  
  const getAuthorization = async () => {
    const formData = new FormData();
    formData.append('grant_type', 'client_credentials');
    formData.append('client_id', AUTH_CLIENT_ID);
    formData.append('client_secret', AUTH_CLIENT_SECRET);
  
    return fetch(baseUrl + '/authorization', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: formData,
    }).then((response) => response.json());
  };
    
  
  useEffect(() => {
    (async () => {
      const link = window.location.search;
      const urlParams = new URLSearchParams(link);
      const codeConfirm = urlParams.get("code");
      const email = urlParams.get("email");
      
      const { access_token } = await getAuthorization();

      await fetch(baseUrl + "/v1/app/user/confirm-email", {
        method: "POST",
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${access_token}` },
        body: JSON.stringify({
          email: email,
          code: codeConfirm,
        })
      });
    })();
  }, []);

  // analytics - page viewed event
  useEffect(() => {
    if(!firebase) {
        return;
    }
    firebase.analytics().logEvent(ANALYTIC_EVENT_NAMES.web_page_viewed)
    firebase.analytics().logEvent(ANALYTIC_EVENT_NAMES.web_email_page_viewed)
  }, [firebase])

  return (
    <AppWrapper>
      <Header {...{ location: location.pathname }} />
      <div {...{ style: { paddingTop: "200px", background: "#a7bcdb" } }}></div>
      <BackgroundSection ambassadeurs>
        <div className="container px-4 -my-4">
          <Title variant="lg">Inscription confirmée</Title>
          <p className="pt-4">Votre e-mail a bien été validé. Merci.</p>
          <Button variant="primary" to="/">
            Retour à l'accueil
          </Button>
        </div>
      </BackgroundSection>
      <Footer />
    </AppWrapper>
  );
}
